<template>
  <section class="projects-section bg-dark-subtle" id="whistles">
    <div class="container px-4 px-lg-5">
      <!-- Wood Row-->
      <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
        <div class="col-lg-6">
          <!--          <CarouselComponent-->
          <!--              :carousel-items="woodCarouselModel"-->
          <!--              id="woodCarousel"-->
          <!--              align-height-to="woodText"-->
          <!--          />-->
          <img src="@/assets/img/timber.jpg" class="w-100" />
          <!-- Image by <a href="https://pixabay.com/users/antmoreton-1099848/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=877367">antmoreton</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=877367">Pixabay</a> -->
        </div>
        <div class="col-lg-6" id="woodText">
          <div class="bg-black text-center h-100 project">
            <div class="d-flex h-100">
              <div class="project-text w-100 my-auto text-center text-lg-left">
                <h4 class="text-white">wood</h4>
                <p class="mb-0 text-white-50">
                  I use different hardwoods for our wooden whistles. This ranges
                  from boxwood to ebony and even more exotic sorts. Common to
                  all our whistles is a very thin wall (about 1.5 mm unless it
                  is a very large whistle). The whistles are usually tunable,
                  though I now and then have non-tunable ones on store. To make
                  the joint work I use aluminium. Also, the mouthpiece is
                  protected with an aluminium shell.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Aluminium Row-->
      <div class="row gx-0 justify-content-center">
        <div class="col-lg-6">
          <!--          <CarouselComponent-->
          <!--              :carousel-items="aluminiumCarouselModel"-->
          <!--              id="aluminiumCarousel"-->
          <!--              align-height-to="aluminiumText"-->
          <!--          />-->
          <img src="@/assets/img/alu-grey.jpg" class="w-100" />
          <!-- Image by <a href="https://pixabay.com/users/rualuminas-6165829/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2639751">Russian Aluminium Association</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2639751">Pixabay</a>-->
        </div>
        <div class="col-lg-6 order-lg-first" id="aluminiumText">
          <div class="bg-black text-center h-100 project">
            <div class="d-flex h-100">
              <div class="project-text w-100 my-auto text-center text-lg-right">
                <h4 class="text-white">aluminium</h4>
                <p class="mb-0 text-white-50">
                  Aluminium whistles are made from the aluminium alloy EN AW
                  6060 which is suitable for kitchen utilities. The solder used
                  for the mouthpiece is lead free. All whistles are tunable with
                  a cork seal for the joint. The wall is as thin as technically
                  possible for a jointed whistle.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Brass Row-->
      <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
        <div class="col-lg-6">
          <!--          <CarouselComponent-->
          <!--              :carousel-items="woodCarouselModel"-->
          <!--              id="brassCarousel"-->
          <!--              align-height-to="brassText"-->
          <!--          />-->
          <img src="@/assets/img/brass-ebony-grey.jpg" class="w-100" />
        </div>
        <div class="col-lg-6" id="brassText">
          <div class="bg-black text-center h-100 project">
            <div class="d-flex h-100">
              <div class="project-text w-100 my-auto text-center text-lg-left">
                <h4 class="text-white">brass &amp; ebony</h4>
                <p class="mb-0 text-white-50">
                  Brass whistles are made from a lead free brass alloy with an
                  ebony (or other hardwood) fipple. All whistles are tunable
                  with a cork seal for the joint. The wall is as thin as
                  technically possible for a jointed whistle.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
// import CarouselComponent, {ICarouselItemData} from "@/components/util/CarouselComponent.vue";
import { onMounted } from "vue";

// const woodCarouselModel: ICarouselItemData[] = [
//   {
//     active: true,
//     header: "Timber",
//     image: {
//       src: require("@/assets/img/timber.jpg"),
//       alt: "A stack of boxwood",
//     },
//   },
//   {
//     active: false,
//     header: "Ebony Whistle",
//     image: {
//       src: require("@/assets/img/hand-03.jpg"),
//       alt: "A whistle built from ebony and aluminium",
//     },
//   },
//   {
//     active: false,
//     header: "Whistle",
//     image: {
//       src: require("@/assets/img/whistle.jpg"),
//       alt: "A whistle made from ebony",
//     },
//   },
// ];
//
// const aluminiumCarouselModel: ICarouselItemData[] = [
//   {
//     active: true,
//     header: "Timber",
//     image: {
//       src: require("@/assets/img/timber.jpg"),
//       alt: "A stack of boxwood",
//     },
//   },
//   {
//     active: false,
//     header: "Ebony Whistle",
//     image: {
//       src: require("@/assets/img/hand-03.jpg"),
//       alt: "A whistle built from ebony and aluminium",
//     },
//   },
//   {
//     active: false,
//     header: "Whistle",
//     image: {
//       src: require("@/assets/img/whistle.jpg"),
//       alt: "A whistle made from ebony",
//     },
//   },
// ];

onMounted(() => {
  //const alignToAluminium = document.getElementById("aluminiumText");
  // function belongsToCarousel(
  //     carouselId: string,
  //     cItemEl: HTMLElement
  // ): boolean {
  //   if (null === cItemEl) {
  //     return false;
  //   }
  //
  //   if (cItemEl.id === carouselId) {
  //     return true;
  //   }
  //
  //   return (
  //       null !== cItemEl.parentElement &&
  //       belongsToCarousel(carouselId, cItemEl.parentElement)
  //   );
  // }
  // function alignHeight(baseElId: string) {
  //   const alignTo = document.getElementById(baseElId);
  //   if (alignTo !== undefined && alignTo !== null) {
  //     const maxHeight = alignTo.style.maxHeight;
  //     const cItems = document.getElementsByClassName("carousel-item");
  //     for (let cItem of cItems) {
  //       const cItemEl = cItem as HTMLElement;
  //
  //       if (belongsToCarousel(baseElId, cItemEl)) {
  //         cItemEl.style.maxHeight = maxHeight;
  //       }
  //     }
  //   }
  // }
  // alignHeight("woodText");
  // alignHeight("aluminiumText");
});

export default {
  name: "WhistlesSectionComponent",
  // components: {CarouselComponent},
  data: () => {
    return {
      // woodCarouselModel: woodCarouselModel,
      // aluminiumCarouselModel: aluminiumCarouselModel,
    };
  },
};
</script>
