import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-bs-target", "data-bs-toggle", "href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(_ctx.style()),
    "data-bs-target": _ctx.dataBsTarget,
    "data-bs-toggle": _ctx.dataBsToggle,
    href: _ctx.href
  }, _toDisplayString(_ctx.linkText), 11, _hoisted_1))
}