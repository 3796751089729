<template>
  <footer class="footer bg-black small text-center text-light">
    <div class="container px-4 px-lg-5">
      <p class="text-light text-opacity-75">
        copyright &copy; ironwood whistles 2023
      </p>
      <div class="container col-12 col-lg-4 col-md-6">
        <div class="col text-center">
          <FooterLinkComponent
            link-text="impressum"
            target="#impressumModal"
            col-size="12"
          />
          <!--          <FooterLinkComponent-->
          <!--            link-text="agb"-->
          <!--            target="#agbModal"-->
          <!--            col-size="4"-->
          <!--          />-->
          <FooterLinkComponent
            link-text="dsgvo"
            target="#dsgvoModal"
            col-size="12"
          />
          <!--          <FooterLinkComponent-->
          <!--            link-text="cites"-->
          <!--            target="#citesModal"-->
          <!--            col-size="3"-->
          <!--          />-->
        </div>
      </div>
    </div>
  </footer>
  <!-- footer modals -->
  <ImpressumComponent />
  <AgbComponent />
  <DsgvoComponent />
  <CitesComponent />
</template>
<script lang="ts">
import ImpressumComponent from "@/components/general/ImpressumComponent.vue";
import AgbComponent from "@/components/general/AgbComponent.vue";
import DsgvoComponent from "@/components/general/DsgvoComponent.vue";
import CitesComponent from "@/components/general/CitesComponent.vue";
import FooterLinkComponent from "@/components/general/FooterLinkComponent.vue";

export default {
  name: "FooterSectionComponent",
  components: {
    FooterLinkComponent,
    ImpressumComponent,
    AgbComponent,
    DsgvoComponent,
    CitesComponent,
  },
};
</script>
