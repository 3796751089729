import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkComponent = _resolveComponent("LinkComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('col-'.concat($props.colSize))
  }, [
    _createVNode(_component_LinkComponent, {
      href: $props.target,
      "link-text": $props.linkText,
      "data-bs-toggle": "modal",
      "data-bs-target": $props.target,
      inverted: true
    }, null, 8, ["href", "link-text", "data-bs-target"])
  ], 2))
}