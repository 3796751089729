<template>
  <div>
    <MastHeadSectionComponent />
    <AboutSectionComponent />
    <WhistlesSectionComponent />
    <PricesSectionComponent />
    <CustomSectionComponent />
    <ContactSectionComponent />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MastHeadSectionComponent from "@/components/home/MastHeadSectionComponent.vue";
import AboutSectionComponent from "@/components/home/AboutSectionComponent.vue";
import WhistlesSectionComponent from "@/components/home/WhistlesSectionComponent.vue";
import ContactSectionComponent from "@/components/general/ContactSectionComponent.vue";
import PricesSectionComponent from "@/components/home/PricesSectionComponent.vue";
import CustomSectionComponent from "@/components/home/CustomSectionComponent.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    CustomSectionComponent,
    PricesSectionComponent,
    ContactSectionComponent,
    WhistlesSectionComponent,
    AboutSectionComponent,
    MastHeadSectionComponent,
  },
});
</script>

<style src="@/assets/css/style.css"></style>
