<template>
  <div style="margin-top: 5em">
    <div class="container text-center mb-2 w-75">
      <h2 class="text-muted text-center">Prices</h2>
      <p class="bg-info">
        I do not have any online shop functionality here, so if you want to
        order something, just
        <LinkComponent href="/#contact" link-text="contact me" />.
      </p>
      <img
        src="@/assets/img/whistles/amaranth-d-tun.jpg"
        class="w-50 pb-4 pt-2"
        alt="Whistle in D made from amaranth wood."
        title="Whistle in D made from amaranth wood."
      />
      <h3 class="text-muted text-center">Standard Whistles</h3>
      <p>
        Prices are for a standard whistle where I decide on tube diameter, wall
        thickness, hole size &amp; position etc.
      </p>
      <p>
        All whistles are tuned in <b>just intonation</b>. If some other tuning
        makes you happier, let me know when ordering.
      </p>
      <p>
        Though I didn't want to blow up the table with too many rows I
        <em>do</em> make whistles in <b>all keys</b>, if requested.
      </p>
      <p>
        A standard wooden whistle is made from <b>african ebony</b> or some
        equivalent wood. I enjoy using other wood sorts as well,
        <LinkComponent href="/#contact" link-text="please contact me" /> for
        options. Depending on the sort there may be a surcharge.
      </p>
      <p>
        NB: I cannot sell whistles made from <b>CITES</b> protected woods
        (though, of course, legally acquired) outside the EU.
      </p>
      <p>
        <b>About the holes</b>: If not asked to do otherwise, I do not make
        <b>circular</b> holes but use an oval hole shape as I only see
        advantages there. If you wish the holes to be circular, please notify me
        when ordering.
      </p>
      <table class="table table-striped">
        <thead>
          <tr class="table-dark">
            <th scope="col">Key</th>
            <th scope="col">Aluminium</th>
            <th scope="col">Brass/Wood</th>
            <th scope="col">Wood</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in standardItems"
            v-bind:key="item.key"
            :class="item.class"
          >
            <th scope="row">{{ item.key }}</th>
            <td>{{ item.alu }} €</td>
            <td>{{ item.brass }} €</td>
            <td>{{ item.wood }} €</td>
          </tr>
        </tbody>
      </table>
      <h3 class="text-muted text-center pt-5">Custom Whistles</h3>
      <p>
        For a custom whistle
        <LinkComponent href="/#contact" link-text="please contact me" />
        with your ideas and whishes.
      </p>
      <div class="container text-center mb-2 w-75"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LinkComponent from "@/components/util/LinkComponent.vue";

export default defineComponent({
  name: "PricesView",
  components: { LinkComponent },

  data() {
    return {
      standardItems: [
        { key: "High G", alu: "195", brass: "205", wood: "365" },
        { key: "High F", alu: "195", brass: "205", wood: "365" },
        { key: "High E", alu: "195", brass: "205", wood: "365" },
        { key: "High Eb", alu: "195", brass: "205", wood: "365" },
        {
          key: "High D",
          alu: "195",
          brass: "205",
          wood: "365",
          class: "table-info",
        },
        { key: "High C", alu: "195", brass: "205", wood: "365" },
        { key: "High B", alu: "195", brass: "205", wood: "365" },
        { key: "High A", alu: "195", brass: "205", wood: "365" },
        { key: "Low G", alu: "245", brass: "265", wood: "490" },
        { key: "Low F", alu: "245", brass: "265", wood: "490" },
        { key: "Low E", alu: "245", brass: "265", wood: "490" },
        { key: "Low Eb", alu: "245", brass: "265", wood: "490" },
        { key: "Low D", alu: "245", brass: "265", wood: "490" },
        { key: "Low C", alu: "245", brass: "265", wood: "490" },
        { key: "Low B", alu: "295", brass: "345", wood: "695" },
        { key: "Low A", alu: "295", brass: "345", wood: "695" },
      ],
    };
  },
});
</script>

<style src="@/assets/css/style.css"></style>
