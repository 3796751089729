<template>
  <section class="contact-section bg-black" id="contact">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-4 mb-3 mb-md-0">
          <div class="card py-4 h-100">
            <div class="card-body text-center">
              <i class="fas fa-map-marked-alt text-primary mb-2"></i>
              <h4 class="text-lowercase m-0">address</h4>
              <hr class="my-4 mx-auto" />
              <div class="small text-black-50">
                Entenmarkt 12, 53902 Bad Münstereifel, Germany
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3 mb-md-0">
          <div class="card py-4 h-100">
            <div class="card-body text-center">
              <i class="fas fa-envelope text-primary mb-2"></i>
              <h4 class="text-lowercase em-0">email</h4>
              <hr class="my-4 mx-auto" />
              <div class="small">
                <LinkComponent
                  href="mailto:info@ironwood-whistles.com"
                  link-text="info@ironwood-whistles.com"
                  class="text-black-50"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3 mb-md-0">
          <div class="card py-4 h-100">
            <div class="card-body text-center">
              <i class="fas fa-mobile-alt text-primary mb-2"></i>
              <h4 class="text-lowercase m-0">phone</h4>
              <hr class="my-4 mx-auto" />
              <div class="small text-black-50">+49 2253 5448451</div>
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="social d-flex justify-content-center">
        <a class="mx-2" href="#!"><i class="fab fa-twitter"></i></a>
        <a class="mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
        <a class="mx-2" href="#!"><i class="fab fa-github"></i></a>
      </div>
      -->
    </div>
  </section>
</template>
<script lang="ts">
import LinkComponent from "@/components/util/LinkComponent.vue";

export default {
  name: "ContactSectionComponent",
  components: { LinkComponent },
};
</script>
