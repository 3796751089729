<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
    <div class="container px-4 px-lg-5">
      <a href="/">
        <img
          src="@/assets/img/ironwood-logo.svg"
          alt="Ironwood Logo"
          class="mx-2"
          style="
            padding-bottom: 5px;
            height: 2em;
            filter: invert(79%) sepia(38%) saturate(4886%) hue-rotate(2deg)
              brightness(107%) contrast(95%);
          "
        />
      </a>
      <a class="navbar-brand" href="/">ironwood whistles</a>
      <button
        class="navbar-toggler navbar-toggler-right"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <font-awesome-icon icon="fa-solid fa-bars"></font-awesome-icon>
        <i class="fas fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              whistles
            </a>
            <ul class="dropdown-menu border-0" aria-labelledby="navbarDropdown">
              <!--
              <li>
                <a class="dropdown-item text-dark" href="/whistles/models">models</a>
              </li>
-->
              <li>
                <a class="dropdown-item" href="/#prices">prices</a>
              </li>
              <!--
              <li>
                <a class="dropdown-item text-dark" href="/whistles/on-stock"
                  >on stock</a
                >
              </li>
-->
              <li>
                <a class="dropdown-item" href="/#custom">custom whistles</a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#about">about ironwood</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#contact">contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script lang="ts">
export default {
  name: "NavigationSectionComponent",
};
</script>
