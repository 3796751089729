import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MastHeadSectionComponent = _resolveComponent("MastHeadSectionComponent")!
  const _component_AboutSectionComponent = _resolveComponent("AboutSectionComponent")!
  const _component_WhistlesSectionComponent = _resolveComponent("WhistlesSectionComponent")!
  const _component_PricesSectionComponent = _resolveComponent("PricesSectionComponent")!
  const _component_CustomSectionComponent = _resolveComponent("CustomSectionComponent")!
  const _component_ContactSectionComponent = _resolveComponent("ContactSectionComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MastHeadSectionComponent),
    _createVNode(_component_AboutSectionComponent),
    _createVNode(_component_WhistlesSectionComponent),
    _createVNode(_component_PricesSectionComponent),
    _createVNode(_component_CustomSectionComponent),
    _createVNode(_component_ContactSectionComponent)
  ]))
}