ImpressumComponent.vue
<template>
  <div class="modal" tabindex="-1" id="agbModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <ModalTitleComponent title="Allgemeine Geschäftsbedingungen" />
        <div class="modal-body">
          <ul class="list-unstyled">
            <li>1. Geltungsbereich</li>
            <li>2. Vertragspartner</li>
            <li>3. Vertragsschluss</li>
            <li>4. Widerrufsrecht</li>
            <li>5. Preise und Versandkosten</li>
            <li>6. Lieferung</li>
            <li>7. Zahlung</li>
            <li>8. Eigentumsvorbehalt</li>
            <li>9. Streitbeilegung</li>
          </ul>
          <p></p>
          <p></p>
          <h3>1. Geltungsbereich</h3>
          <p>
            Für alle Lieferungen von Ironwood Whistles an Verbraucher gelten
            diese Allgemeinen Geschäftsbedingungen (AGB).
          </p>
          <p>
            Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu
            einem Zwecke abschließt, der überwiegend weder ihrer gewerblichen
            noch ihrer
          </p>
          selbstständigen beruflichen Tätigkeit zugerechnet werden kann.
          <p></p>
          <h3>2. Vertragspartner</h3>
          <p>
            Der Kaufvertrag kommt zustande mit Ironwood Whistles, Inhaber: Tarek
            Ahmed, Handelsregister: Amtsgericht Köln, xxxxx.
          </p>
          <p></p>
          <h3>3. Vertragsschluss</h3>
          <p>
            3.1. Die Darstellung der Produkte im Online-Shop stellt kein
            rechtlich bindendes Angebot, sondern nur eine Aufforderung zur
            Bestellung dar.
          </p>
          <p>
            3.2. Durch Anklicken des Buttons [Order for xx.xx €] geben Sie eine
            verbindliche Bestellung der auf der Bestellseite aufgelisteten Waren
            ab. Ihr
          </p>
          Kaufvertrag kommt zustande, wenn wir Ihre Bestellung durch eine
          Auftragsbestätigung per E-Mail unmittelbar nach dem Erhalt Ihrer
          Bestellung annehmen.
          <p></p>
          <h3>4. Widerrufsrecht</h3>
          <p>
            4.1. Wenn Sie Verbraucher sind (also eine natürliche Person, die die
            Bestellung zu einem Zweck abgibt, der weder Ihrer gewerblichen oder
            selbständigen
          </p>
          beruflichen Tätigkeit zugerechnet werden kann), steht Ihnen nach
          Maßgabe der gesetzlichen Bestimmungen ein Widerrufsrecht zu.
          <p>
            4.2. Machen Sie als Verbraucher von Ihrem Widerrufsrecht nach Ziffer
            4.1 Gebrauch, so haben Sie die regelmäßigen Kosten der Rücksendung
            zu tragen.
          </p>
          <p>
            4.3. Im Übrigen gelten für das Widerrufsrecht die Regelungen, die im
            Einzelnen wiedergegeben sind in der folgenden
          </p>
          <p></p>
          <p></p>
          <blockquote
            class="blockquote px-4"
            style="border-left: 1px solid silver"
          >
            <p class="font-weight-bold">Widerrufsbelehrung</p>
            <p></p>
            <p>Widerrufsrecht</p>
            <p></p>
            <p>
              Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
              diesen Vertrag zu widerrufen.
            </p>
            <p></p>
            <p>
              Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag an dem Sie
              oder ein von Ihnen benannter Dritter, der nicht der Beförderer
              ist, die Waren in Besitz
            </p>
            genommen haben bzw. hat.
            <p></p>
            <p>
              Um Ihr Widerrufsrecht auszuüben, müssen Sie uns, Ironwood
              Whistles, Entenmarkt 12, 53902 Bad Münstereifel,
              info@ironwood-whistles.com, mittels einer eindeutigen
            </p>
            Erklärung (z.B. ein mit der Post versandter Brief oder eine E-Mail)
            über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.
            Sie können dafür das beigefügte Muster-Widerrufsformular verwenden,
            das jedoch nicht vorgeschrieben ist. Sie können das
            Muster-Widerrufsformular oder eine andere eindeutige Erklärung auch
            auf unserer Webseite (Internet-Adresse einfügen) elektronisch
            ausfüllen und übermitteln. Machen Sie von dieser Möglichkeit
            Gebrauch, so werden wir Ihnen unverzüglich (z.B. per E-Mail) eine
            Bestätigung über den Eingang eines solchen Widerrufs übermitteln.
            <p></p>
            <p>
              Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
              Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
              Widerrufsfrist absenden.
            </p>
            <p></p>
            <p>Folgen des Widerrufs</p>
            <p></p>
            <p>
              Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle
              Zahlungen, die wir von Ihnen erhalten haben, einschließlich der
              Lieferkosten (mit Ausnahme der
            </p>
            zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere
            Art der Lieferung als die von uns angebotene, günstigste
            Standardlieferung gewählt haben), unverzüglich und spätestens binnen
            vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
            Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese
            Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
            ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
            wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden
            Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die
            Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten
            haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren
            zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
            <p></p>
            <p>
              Sie haben die Waren unverzüglich und in jedem Fall spätestens
              binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf
              dieses Vertrages
            </p>
            unterrichten, an uns oder an (hier sind gegebenenfalls der Name und
            die Anschrift der von Ihnen zur Entgegennahme der Ware ermächtigten
            Person einzufügen) zurückzusenden oder zu übergeben. Die Frist ist
            gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen
            absenden.
            <p></p>
            <p>
              Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
            </p>
            <p></p>
            <p>
              Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen,
              wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit,
              Eigenschaften und
            </p>
            Funktionsweise der Waren nicht notwendigen Umgang mit ihnen
            zurückzuführen ist.
            <p></p>
            <p>- Ende der Widerrufsbelehrung -</p>
          </blockquote>
          <p></p>
          <p></p>
          <p></p>
          <h3>5. Preise und Versandkosten</h3>
          <p>
            5.1. Die auf den Produktseiten genannten Preise enthalten die
            gesetzliche Mehrwertsteuer und sonstige Preisbestandteile.
          </p>
          <p>
            5.2. Zusätzlich zu den angegebenen Preisen berechnen wir für die
            Lieferung innerhalb Deutschlands pauschal 8,-- € pro Bestellung. Die
            Versandkosten
          </p>
          werden Ihnen auf den Produktseiten, im Warenkorbsystem und auf der
          Bestellseite nochmals deutlich mitgeteilt.
          <p>
            5.3. Bei Zahlung per Nachnahme wird zusätzlich eine Gebühr in Höhe
            von 7,-- € fällig, die der Zusteller vor Ort erhebt. Weitere Steuern
            oder Kosten
          </p>
          fallen nicht an.
          <p></p>
          <h3>6. Lieferung</h3>
          <p>6.1. Die Lieferung erfolgt nur innerhalb Deutschlands mit DHL.</p>
          <p>
            6.2. Die Lieferzeit beträgt bis zu 30 Tage. Auf eventuell
            abweichende Lieferzeiten weisen wir auf der jeweiligen Produktseite
            hin.
          </p>
          <p></p>
          <h3>7. Zahlung</h3>
          <p>7.1. Die Zahlung erfolgt wahlweise per Vorkasse oder Nachnahme.</p>
          <p>
            7.2. Bei Auswahl der Zahlungsart Vorkasse nennen wir Ihnen unsere
            Bankverbindung in der Auftragsbestätigung und liefern die Ware nach
            Zahlungseingang.
          </p>
          <p></p>
          <h3>8. Eigentumsvorbehalt</h3>
          <p>Bis zur vollständigen Zahlung bleibt die Ware unser Eigentum.</p>
          <p></p>
          <h3>9. Streitbeilegung</h3>
          <p>
            Die EU-Kommission hat eine Internetplattform zur Online-Beilegung
            von Streitigkeiten geschaffen. Die Plattform dient als Anlaufstelle
            zur
          </p>
          außergerichtlichen Beilegung von Streitigkeiten betreffend
          vertragliche Verpflichtungen, die aus Online-Kaufverträgen erwachsen.
          Nähere Informationen sind unter dem folgenden Link verfügbar:
          http://ec.europa.eu/consumers/odr. Zur Teilnahme an einem
          Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind
          wir weder bereit noch verpflichtet.
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import ModalTitleComponent from "@/components/util/ModalTitleComponent.vue";

export default {
  name: "AgbComponent",
  components: { ModalTitleComponent },
};
</script>
