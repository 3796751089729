<template>
  <div class="modal" tabindex="-1" id="citesModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <ModalTitleComponent title="CITES" />
        <div class="modal-body">
          <div class="textpage">
            <h1>Washingtoner Artenschutzübereinkommen</h1>
            <p>
              In einigen Flöten verbaue ich <i>legal erworbene</i> Hölzer, die
              dem Washingtoner Artenschutzübereinkommen unterligen (Convention
              on International Trade in Endangered Species of Wild Fauna and
              Flora, kurz CITES). Sollten Sie eine solche Flöte erweben wollen
              ...
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import ModalTitleComponent from "@/components/util/ModalTitleComponent.vue";

export default {
  name: "CitesCompoment",
  components: { ModalTitleComponent },
};
</script>
