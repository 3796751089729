<template>
  <header class="masthead">
    <div
      class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center"
    >
      <div class="d-flex justify-content-center">
        <div class="text-center">
          <svg class="mx-auto">
            <image
              style="
                filter: invert(99%) sepia(1%) saturate(991%) hue-rotate(299deg)
                  brightness(112%) contrast(100%);
                height: 9em;
              "
              xlink:href="@/assets/img/ironwood-logo.svg"
            />
          </svg>
          <h1 class="mx-auto my-0">ironwood whistles</h1>
        </div>
      </div>
    </div>
  </header>
</template>
<script lang="ts">
export default {
  name: "MastHeadSectionComponent",
};
</script>
