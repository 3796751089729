<template>
  <div style="margin-top: 5em" id="custom">
    <h3 class="text-muted text-center pb-2">custom whistles</h3>
    <div class="container text-center mb-5 w-75">
      <p>
        <LinkComponent href="/#contact" link-text="Contact me" /> for a custom
        whistle made to your specifications. Available materials are aluminium,
        brass and different sorts of wood.
      </p>
      <p>
        There are technical restrictions regarding tube diameter, wall
        thickness, combination of materials etc. I can consult with you to
        arrive at something that works out.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LinkComponent from "@/components/util/LinkComponent.vue";

export default defineComponent({
  name: "CustomSectionComponent",
  components: { LinkComponent },
});
</script>

<style src="@/assets/css/style.css"></style>
