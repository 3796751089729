<template>
  <div id="app">
    <NavigationSectionComponent />
    <router-view class="contentSection" />
    <FooterSectionComponent />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavigationSectionComponent from "@/components/general/NavigationSectionComponent.vue";
import FooterSectionComponent from "@/components/general/FooterSectionComponent.vue";

export default defineComponent({
  name: "App",
  components: {
    NavigationSectionComponent,
    FooterSectionComponent,
  },
});
</script>

<style src="@/assets/css/style.css">
.contentSection {
  background-color: #fff;
}
</style>
