<template>
  <div :class="'col-'.concat(colSize)">
    <LinkComponent
      :href="target"
      :link-text="linkText"
      data-bs-toggle="modal"
      :data-bs-target="target"
      :inverted="true"
    />
  </div>
</template>
<script lang="ts">
import LinkComponent from "@/components/util/LinkComponent.vue";

export default {
  name: "FooterLinkComponent",
  components: {
    LinkComponent,
  },
  props: {
    target: { type: String, required: true },
    linkText: { type: String, required: true },
    colSize: { type: String, required: true },
  },
};
</script>
