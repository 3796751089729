import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faBars,
  faCircleCheck,
  faCircleInfo,
  faCircleXmark,
  faClock,
  faCog,
  faEnvelope,
  faEuroSign,
  faEye,
  faGear,
  faHeadphones,
  faSection,
  faSpinner,
  faTriangleExclamation,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

library.add(faBars);
library.add(faSection);
library.add(faCircleInfo);
library.add(faEnvelope);
library.add(faUser);
library.add(faHeadphones);
library.add(faEuroSign);
library.add(faEye);
library.add(faCircleCheck);
library.add(faCircleXmark);
library.add(faGear);
library.add(faCog);
library.add(faSpinner);
library.add(faClock);
library.add(faTriangleExclamation);

createApp(App)
  .use(store)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
