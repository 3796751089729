<template>
  <section class="about-section text-center" id="about">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5 justify-content-center">
        <div class="col-lg-8">
          <h2 class="text-white mb-4">
            hand crafted whistles in wood, aluminium and brass
          </h2>
          <div class="text-white-50">
            <p>
              Ironwood whistles is a small tin whistle manufacturer in the
              beautiful German region of <i>Eifel</i>. I build whistles
              primarily in wood but offer instruments made from aluminium and
              brass, as well. I do not mass produce or use industrialized
              processes - each instrument is unique and made with love and
              devotion to the craft.
            </p>
            <p>
              <img src="@/assets/img/lathe-grey.jpg" class="w-100" />
            </p>
            <p>
              <LinkComponent
                link-text="Contact ironwood"
                href="#contact"
                :inverted="true"
              />
              for more information, available whistles or custom orders.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import LinkComponent from "@/components/util/LinkComponent.vue";

export default {
  name: "AboutSectionComponent",
  components: { LinkComponent },
};
</script>
