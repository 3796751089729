<template>
  <a
    :class="style()"
    :data-bs-target="dataBsTarget"
    :data-bs-toggle="dataBsToggle"
    :href="href"
    >{{ linkText }}</a
  >
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LinkComponent",
  props: {
    inverted: { type: Boolean, required: false, default: false },
    href: String,
    linkText: String,
    dataBsToggle: { type: String, required: false, default: "" },
    dataBsTarget: { type: String, required: false, default: "" },
  },
  methods: {
    style() {
      return !this.inverted
        ? "text-muted fw-bold link-dark text-decoration-none link-opacity-25-hover"
        : "link-light fw-bold link-opacity-75 text-decoration-none link-opacity-25-hover";
    },
  },
});
</script>
