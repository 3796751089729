<template>
  <div class="modal-header">
    <h1 class="modal-title">{{ title }}</h1>
    <button
      type="button"
      class="btn-close align-text-top"
      data-bs-dismiss="modal"
      aria-label="Close"
    ></button>
  </div>
</template>
<script lang="ts">
export default {
  name: "ModalTitleComponent",
  props: {
    title: String,
  },
};
</script>
